<template>
<div class="tw-flex tw-w-full tw-flex-col lg:">
  <icons name="arrow-back" />
  <div class="company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5">
    <div class="tw-flex tw-justify-between tw-w-full tw-items-center tw-flex-wrap">
      <h6 class="header">Developers</h6>
      <div
          class="mr-2 tw-mt-5 lg:tw-mt-0 "
          style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)"
      >
        <v-text-field
            placeholder="Search for developer"
            class="p-0"
            solo
            flat
            hide-details
            v-model="search"
        >
          <template #prepend>
            <v-icon :color="color" class="ml-5">
              mdi-magnify
            </v-icon>
          </template>
          <template #append>
            <v-icon :color="color">
              mdi-filter-variant
            </v-icon>
          </template>
        </v-text-field>
      </div>
      <v-expansion-panels v-model="panel" flat>
        <v-expansion-panel  v-for="(developer,index) in developers" :key="index" >
          <v-expansion-panel-header>
            <div class="tw-flex tw-w-full tw-items-center tw-justify-between">
              <div class="tw-w-1/12">
                <img
                    :src="developer.logo"
                    alt="logo"
                    v-if="developer.logo"
                    class="company-logo"
                />
                <div
                    class="no-image"
                    v-else
                    :style="{ backgroundColor: color }"

                >
                  <h6 class="tw-flex tw-justify-center tw-items-center " v-if="developer.companyName">
                    {{ developer.companyName[0] }}
                  </h6>
                </div>
              </div>
              <div class="tw-w-2/12">
                <label class="td-style">{{ developer.companyName }}</label>
              </div>
              <div class="tw-w-2/12">
                <label class="td-style">{{ developer.email }}</label>
              </div>
              <div class="tw-w-1/12">
                <label class="td-style">{{ developer.phoneNumber }}</label>
              </div>
              <div class="tw-w-1/12">
                <span class="td-style">
                <v-switch v-model="developer.companyActivated" @change="toggleVerify(developer)"
                      inset
                      color="#004AAD"></v-switch>
                </span>
              </div>
              <div class="tw-w-1/12">
                <v-menu bottom offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <td class="d-flex justify-center">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon :color="color">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </td>
                  </template>
                  <v-list>
                    <v-list-item class="" >
                      <v-list-item-title
                          style="color: black"
                          class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                        <img
                            alt=""
                            class="tw-mr-2 icon-size"
                        />
                        View
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="developer.companyActivated">
           <div class="tw-flex tw-w-full tw-flex-col">
             <sub-scribe-to-companies :developer-data="developer"/>
           </div>
          </v-expansion-panel-content>
          <v-divider inset ></v-divider>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
  <modal :dialog="showModal" :title="modalTitle"
         :description="modalDesc" icon="oops"
         @close="handleModal" @verifyTransportCompany="verifyDeveloper"
        :loading="modalLoading"/>
</div>
</template>

<script>
import Icons from "../../../components/reuseables/Icons.vue";
import {activateDeveloper, getAllDevelopers} from "@/services/api/APIService";
import Modal from "../../../components/reuseables/Modal.vue";
import SubScribeToCompanies from "@/views/product/developer/SubScribeToCompanies.vue";
export default {
  name: "VerifyDeveloperView",
  components: {SubScribeToCompanies, Modal, Icons},
  data(){
    return{
      color: "#004AAD",
      search : "",
      developers:[],
      not_found : "Developers will show here",
      headers: [
        { text: "", value: "logo" },
        { text: "", value: "companyName" },
        { text: "", value: "email" },
        { text: "", value: "phoneNumber" },
        { text: "", value: "verification" },
        { text: "", value: "actions", align: "end" },
      ],
      loading : false,
      showModal : false,
      modalLoading : false,
      modalTitle: "",
      modalDesc : " ",
      developerId:null,
      panel:0

    }
  },

  methods:{
    handleModal(){
      if (this.showModal){
        this.getAllDevelopers()
      }
      this.showModal = !this.showModal

    },
   async verifyDeveloper(){
      this.modalLoading = true
      if (this.developerId) {
        await activateDeveloper(this.developerId).then(res => {
          this.$displaySnackbar({
            message: res.data.detail,
            success: true
          })
          this.modalLoading = false
          this.handleModal()

        }).catch(err => {
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false
          })
          this.modalLoading = false
        })
      }
    },
   async getAllDevelopers(){
      this.loading = true
      await getAllDevelopers().then(res =>{
        console.log(res.data)
        this.developers = res.data
        this.loading = false
      }).catch(err => {
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false
        })
        this.loading = false
      }).finally(() => this.loading = false)
    },
    toggleVerify(item){
     if (!item.companyVerified){
       this.modalTitle = "Verify"
       this.modalDesc ="Are you sure, you want to verify "+ item.companyName+ "?"
     }
     else {
       this.modalTitle = "Unverify"
       this.modalDesc ="Are you sure, you want to unverify "+ item.companyName+ "?"
     }
     this.developerId = item.id
     this.handleModal()
    },
    getGradeRange(grade){
      if (grade === 'GRADEONE'){
        return "5 - 10"
      }
      else if (grade === 'GRADETWO'){
        return "10 - 20"
      }
      else if (grade === 'GRADETHREE'){
        return "20 - 50"
      }
      else if (grade === 'GRADEFOUR'){
        return "50 - 100"
      }
      else if (grade === 'GRADEFIVE'){
        return "100 - 200"
      }
      else if (grade === 'GRADESIX'){
        return "200 - 500"
      }
      else if (grade === 'GRADESEVEN'){
        return "500 - 1000"
      }
      else if (grade === 'GRADEEIGHT'){
        return "1000 - 2000"
      }
      else if (grade === 'GRADENINE'){
        return "2000 - 5000"
      }
      else if (grade === 'GRADETEN'){
        return "5000 - 10000"
      }
      else if (grade === 'GRADEELEVEN'){
        return "10000 - 20000"
      }
      else if (grade === 'GRADETWELVE'){
        return "20000 - 50000"
      }
      else if (grade === 'GRADETHIRTEEN'){
        return "50000 - 100000"
      }
      else if (grade === 'GRADEFOURTEEN'){
        return "100000 - 200000"
      }
      else if (grade === 'GRADEFIFTEEN'){
        return "200000 - 500000"
      }
      else if (grade === 'GRADESIXTEEN'){
        return "500000 - 1000000"
      }
    }
  },
 async created() {
    await this.getAllDevelopers()
  }
}
</script>

<style scoped lang="scss">
.company-dashboard{
  display: flex;
  background: #FFFFFF;
  width: 100%;
  min-height: 70vh;
  max-height: available;
  border-radius: 10px;
  flex-direction: column;
}
.header{
  font-family: 'Inter', sans-serif ;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #004AAD;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.table{
  width: 100%;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  text-transform: capitalize;

}
.company-logo{
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.no-image{
  display: flex;
    height: 32px;
    width: 32px;
    border-radius: 50%;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
}
</style>